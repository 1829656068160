var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _c(
        "div",
        { staticClass: "button-block" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.refreshDataMixin()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("refresh")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "delete",
                  expression: "'delete'",
                },
              ],
              attrs: { type: "danger", round: "", plain: "" },
              on: { click: _vm.deleteFile },
            },
            [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: { data: _vm.tableData, height: "100%", stripe: "" },
              on: { "selection-change": _vm.onSelectionChangeMixin },
            },
            [
              _c("base-no-data", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: _vm.$t("serialNum"),
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "originalName",
                  label: _vm.$t("filename"),
                  align: "center",
                  width: "350",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bizId",
                  label: _vm.$t("bizId"),
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bizType",
                  label: _vm.$t("bizType"),
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userId",
                  label: _vm.$t("userId"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("createTime"),
                  width: "160",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: _vm.$t("operate"),
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "view",
                                expression: "'view'",
                              },
                            ],
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.previewFile(scope.row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("preview")) + " ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "delete",
                                expression: "'delete'",
                              },
                            ],
                            staticStyle: { color: "#f56c6c" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteFileMixin(scope.row.id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": _vm.pageSizes,
              "page-size": _vm.pageSize,
              "current-page": _vm.pageIndex,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChangeMixin,
              "current-change": _vm.handleCurrentChangeMixin,
            },
          }),
        ],
        1
      ),
      _c("file-preview", {
        attrs: { visible: _vm.filePreviewVisible, file: _vm.currentFile },
        on: {
          "update:visible": function ($event) {
            _vm.filePreviewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }